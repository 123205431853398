<template>
  <section>
    <TopBanner title="Products" :src="require('@/assets/images/banners/banner_product.jpg')" />
    <v-container 
      class="content-container"
    >
      <section
        v-if="errorMessage === null"
      >
        <section
          v-if="stages && programList"
        >
          <div 
            id="Products"
            class="my-4"
          >
            <div class="text-center">
              <p class="primary--text text-h5 text-sm-h3 font-weight-bold">
                Your Clients' Mortgage journey starts here
              </p>
              <p class="primary--text text-body-1 text-sm-h5 font-weight-light">
                Let New Wave Lending be your Partner!
              </p>
            </div>
            
            <div class="text-center my-4">
              <a class="text-decoration-none" href="javascript:void(0)" :to="$store.state.brokerPortalUrl">
                <span class="primary--text text-h6"> 
                  <v-icon
                    color="primary"
                  >
                    mdi-account-lock
                  </v-icon>
                  Login for the 
                  <span class="font-weight-bold">
                    PRICER
                  </span>
                </span>
              </a>
            </div>
          </div>

          <section
            v-if="!$vuetify.breakpoint.mdAndDown"
          >
            <v-row
              id="main-category"
              class="fill-height"
              align="center"
              justify="center"
            >
              <v-col
                v-for="(mainCategory, index) in categoryMap.keys()"
                :key="'main-category-' + index"
                cols="12"
                sm="6"
                md="3"
              >
                <v-hover v-slot="{ hover }">
                  <v-card
                    @click="clickMainCategory(mainCategory)"
                    class="pa-4"
                    flat
                    rounded
                    :color="(mainCategory === selectedMainCategory) ? 'grey lighten-1' : ''"
                  >
                    <div class="text-center">
                      <v-img
                        :src="images[index % images.length]"
                        :class="hover ? 'mx-auto my-5 image-turn-90' : 'mx-auto my-5 image-turn-0'"
                        width="80px"
                        height="auto"
                      ></v-img>
                    </div>
                    <div class="text-body-1 text-uppercase text-center">
                      {{ mainCategory }}
                    </div>
                  </v-card>
                </v-hover>
              </v-col>
            </v-row>
          </section>

          <section
            v-else
          >
            <swiper class="swiper" :options="swiperOption">
              <swiper-slide 
                v-for="(mainCategory, index) in categoryMap.keys()"
                :key="'swiper-slide-' + index"  
              >
                <v-hover v-slot="{ hover }">
                  <v-card
                    @click="clickMainCategory(mainCategory)"
                    :class="index % 2 === 0 ? 'pa-1 ml-auto' : 'pa-1 mr-auto'"
                    flat
                    rounded
                    max-width="100px"
                    :color="(mainCategory === selectedMainCategory) ? 'grey lighten-1' : ''"
                  >
                    <div class="text-center">
                      <v-img
                        :src="images[index % images.length]"
                        :class="hover ? 'mx-auto my-5 image-turn-90' : 'mx-auto my-5 image-turn-0'"
                        width="50px"
                        height="auto"
                      ></v-img>
                    </div>
                    <div class="text-caption text-uppercase text-center">
                      {{ mainCategory }}
                    </div>
                  </v-card>
                </v-hover>
              </swiper-slide>
              <div class="swiper-button-prev" slot="button-prev"></div>
              <div class="swiper-button-next" slot="button-next"></div>
            </swiper>
          </section>

          <v-card
            id="sub-category"
            class="px-4 my-4"
            flat
          >
            <!-- <div :class="$vuetify.breakpoint.smAndDown  ? 'text-left text-h6 grey--text' : 'text-center text-h6 grey--text'">
              {{ selectedMainCategory }}
            </div> -->
            <v-timeline
              v-if="selectedMainCategory"
              align-top
              :dense="$vuetify.breakpoint.smAndDown"
            >
              <v-timeline-item hide-dot></v-timeline-item>
              <v-timeline-item
                v-for="(subCategory, index) in categoryMap.get(selectedMainCategory)"
                :key="'time-line-' + index"
                :color="timelineAttributes[index % timelineAttributes.length].color"
                :icon="timelineAttributes[index % timelineAttributes.length].icon"
                fill-dot
              >
                <v-card
                  :color="timelineAttributes[index % timelineAttributes.length].color"
                  dark
                >
                  <v-card-title :class="$vuetify.breakpoint.smAndDown ? 'text-caption font-weight-bold' : 'text-subtitle-1 font-weight-bold'">
                    <v-btn 
                      class="pa-0"
                      @click="showGuideline(programList.find(x => x.productGroupName === selectedMainCategory && x.guidelineGroupName === subCategory).guidelineFileDownloadLink)"
                      text
                    >
                    {{ subCategory }}
                    </v-btn>
                    <v-btn
                      @click="showGuideline(programList.find(x => x.productGroupName === selectedMainCategory && x.guidelineGroupName === subCategory).guidelineFileDownloadLink)"
                      icon
                    >
                      <v-icon
                        color="white"
                        small
                      >
                        mdi-open-in-new
                      </v-icon>
                    </v-btn>
                    <v-spacer></v-spacer>
                    <v-btn
                      outlined
                      fab
                      x-small
                      @click="tempCollapse.find(x => x.name === subCategory).collapse = !tempCollapse.find(x => x.name === subCategory).collapse"
                    >
                      <v-icon
                        v-if="tempCollapse.find(x => x.name === subCategory).collapse === true"
                        color="white"
                      >
                        mdi-plus
                      </v-icon>
                      <v-icon
                        v-else
                        color="white"
                      >
                        mdi-minus
                      </v-icon>
                    </v-btn>
                  </v-card-title>
                  <v-card-text class="white text--primary pt-2" :hidden="tempCollapse.find(x => x.name === subCategory).collapse">
                    <div 
                      v-for="program in programList.filter(x => x.productGroupName === selectedMainCategory && x.guidelineGroupName === subCategory)"
                      :key="program.loanProgramName"
                      class="my-1"
                    >
                      <v-icon
                        :color="timelineAttributes[index % timelineAttributes.length].color"
                        class="mr-1"
                      >
                        mdi-check-all
                      </v-icon>
                      <span>{{ program.loanProgramName }}</span>
                    </div>
                  </v-card-text>
                </v-card>
              </v-timeline-item>
              <!-- <div :class="$vuetify.breakpoint.smAndDown  ? 'text-left' : 'text-center'">
                <v-icon
                  class="mt-4"
                  color="grey"
                >
                  mdi-circle
                </v-icon>
              </div> -->
            </v-timeline>
          </v-card>
        </section>
        <section v-else>
          <v-skeleton-loader
            class="mx-auto"
            type="image"
          ></v-skeleton-loader>
          <br />
          <v-skeleton-loader
            class="mx-auto"
            type="image@2"
          ></v-skeleton-loader>
        </section>
      </section>
      <section v-else>
        <span class="error--text">{{ errorMessage }}</span>
      </section>
    </v-container>
    <!-- PDF Viewer Modal -->
    <PdfViewer :src="targetPdfPath" />
    <Footer />
  </section>
</template>

<style>
#main-category .image-turn-90 {
  transition: .3s;
  transform: rotate(-45deg);
}

#main-category .image-turn-0 {
  transition: .3s;
  transform: rotate(0deg);
}

</style>

<script>
import TopBanner from "@/components/TopBanner";
import PdfViewer from "@/components/PdfViewer";
import Footer from "@/views/layouts/Footer";
import { swiper, swiperSlide } from 'vue-awesome-swiper';
import axios from "axios";

export default {
  components: {
    TopBanner,
    PdfViewer,
    swiper,
    swiperSlide,
    Footer
  },
  async created(){
    this.turnTimes = await this.getTurnTimeTable();
    this.programList = await this.getProgramList();

    this.stages = [...new Set(this.turnTimes.map(x => x.stageName))];

    if(this.turnTimes.length === 0 || this.programList === 0){
      this.errorMessage = "Sorry, Failed to load data.";
    }
  },
  data(){
    return {
      tab: null,
      stages: null,
      turnTimes: null,
      programList: null,
      categoryMap: null,
      targetPdfPath: null,
      errorMessage: null,
      selectedMainCategory: null,
      subCategoryCollapseMap: null,
      tempCollapse: null,
      swiperOption: {
        slidesPerView: 2,
        spaceBetween: 30,
        slidesPerGroup: 2,
        loopFillGroupWithBlank: true,
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        }
      },
      timelineAttributes: [
        {
          color: 'red lighten-2',
          icon: 'mdi-star',
        },
        {
          color: 'purple darken-1',
          icon: 'mdi-book-variant',
        },
        {
          color: 'green lighten-1',
          icon: 'mdi-airballoon',
        },
        {
          color: 'indigo',
          icon: 'mdi-rocket',
        },
      ],
      images: [
        require("@/assets/images/etc/shape_circle1.png"),
        require("@/assets/images/etc/shape_circle2.png"),
        require("@/assets/images/etc/shape_circle3.png"),
        require("@/assets/images/etc/shape_circle4.png"),
        require("@/assets/images/etc/shape_circle5.png"),
        require("@/assets/images/etc/shape_circle6.png"),
      ],
    }
  },
  methods: {
    async getTurnTimeTable(){
      const res = await axios.get(`${process.env.VUE_APP_API_BASE_URL}/api/Feature/Public/TurnTimes`,
        {
          headers: {
            Authorization: `Basic ${btoa(this.$store.state.basicAuth.userName + ':' + this.$store.state.basicAuth.password)}`,
          },
        })
        .then(resp => {
          return resp.data;
        })
        .catch(() => {
          return [];
        });

      return res;
    },
    async getProgramList(){
      const res = await axios.get(`${process.env.VUE_APP_API_BASE_URL}/api/Feature/LookUp/Resource/ProgramList`,
        {
          headers: {
            Authorization: `Basic ${btoa(this.$store.state.basicAuth.userName + ':' + this.$store.state.basicAuth.password)}`,
          },
        })
        .then(resp => {
          return resp.data;
        })
        .catch(() => {
          return [];
        });
        const mainCategories = [...new Set(res.map(x => x.productGroupName))];

        var myMap = new Map();
        this.tempCollapse = [];

        for(var i = 0; i < mainCategories.length; i++){
          const subCategoryArr = [...new Set(res.filter(x => x.productGroupName == mainCategories[i]).map(x => x.guidelineGroupName))];
          myMap.set(mainCategories[i], subCategoryArr);

          subCategoryArr.forEach(e => {
            this.tempCollapse.push({
              name: e,
              collapse: true,
            });
          });
        }

        this.categoryMap = myMap;

        return res;
    },
    showGuideline(path){
      this.targetPdfPath = path;
      this.$modal.show("pdf-viewer");
    },
    clickMainCategory(mainCategory){
      this.selectedMainCategory = mainCategory;
      this.$vuetify.goTo('#sub-category', { offset: 55, duration: 1000 })
    }
  }
}
</script>