<template>
  <section>
    <v-img
      :src="src"
      :height="$vuetify.breakpoint.mdAndDown ? 250 : 400"
      gradient="0deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0.3) 45%, rgba(0,0,0,0.65) 80%"
      :position="position ? position : '50% 50%'"
    >
      <div class="mx-5" style="width: 100%; height: 100%; position: relative;">
        <div style="position: absolute; bottom: 0">
          <p class="text-h4 font-weight-bold white--text">{{ title }}</p>
        </div>
      </div>
    </v-img>
  </section>
</template>

<script>
export default {
  props: {
    title: String,
    src: String,
    position: String,
  }
}
</script>