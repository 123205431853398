<template>
  <modal
    name="pdf-viewer"
    height="auto"
    :min-width="250"
    :min-height="300"
    :clickToClose="false"
    :draggable="$vuetify.breakpoint.mdAndDown ? false : true"
    :adaptive="true"
    @before-open="beforeOpen"
    @before-close="beforeClose"
  >
    <div class="modal-content">
      <div class="text-right all-scroll">
        <v-btn @click="fullScreen()" icon light>
          <v-icon>mdi-fullscreen</v-icon>
        </v-btn>
        <v-btn @click="close()" icon light>
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </div>
      <iframe :src="cSrc" width="100%" height="800" allowfullscreen></iframe>
    </div>
  </modal>
</template>
<script>
export default {
  name: "PdfViewer",
  props: ["src"],
  computed: {
    cSrc: function (){
      return this.src + '?var=' + Math.random();
    }
  },
  methods: {
    fullScreen() {
      window.open(this.cSrc);
      this.$modal.hide("pdf-viewer");
    },
    close() {
      this.$modal.hide("pdf-viewer");
    },
    beforeOpen() {
      // Prevent background scorll issue
      document.documentElement.classList.add("overflow-y-hidden");
    },
    beforeClose() {
      // Prevent background scorll issue
      document.documentElement.classList.remove("overflow-y-hidden");
    },
  },
};
</script>
